<template>
  <div>
    <b-card no-body>
      <div class="mx-2 mt-2 mb-50">
        <b-row>
          <b-col
            cols="12"
            md="8"
            sm="6"
          >
            <h4 class="mb-0">
              主機列表
            </h4>
          </b-col>

          <b-col
            cols="12"
            md="4"
            sm="6"
          >
            <div class="text-nowrap d-flex justify-content-end">
              <!-- <div
                v-b-modal.add-modal
                v-b-tooltip.hover.v-secondary
                title="新增"
                class="d-flex align-items-center actions-link-btn"
              >
                <b-img
                  src="/admin/images/table/plus.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div> -->

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn"
                @click="refetchTable"
              >
                <!-- ml-25 -->
                <b-img
                  src="/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                  rounded
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="列表顯示"
                class="d-flex align-items-center actions-link-btn ml-25"
              >
                <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  right
                >
                  <template #button-content>
                    <b-img
                      src="/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </template>

                  <b-dropdown-form>
                    <b-form-group>
                      <label class="mb-50">
                        列表顯示
                      </label>

                      <b-form-checkbox
                        v-for="column in tableColumnsSearchable"
                        :key="column.key"
                        v-model="column.select"
                        name="table-column"
                        class="mb-1"
                      >
                        {{ column.label }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-dropdown-form>

                </b-dropdown>
              </div>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 顯示個數/搜尋框 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示個數 -->
          <b-col
            cols="12"
            md="6"
            sm="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-sm-0"
          >
            <!-- <label class="text-nowrap">顯示</label> -->
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="table-perPage-select"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
          </b-col>

          <!-- 搜尋框 -->
          <b-col
            cols="12"
            md="6"
            sm="8"
          >
            <!-- 搜尋框 -->
            <div class="w-100 mr-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  debounce="500"
                  class="d-inline-block"
                  placeholder="搜尋..."
                />
                <b-input-group-append
                  v-if="searchQuery"
                  is-text
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer text-muted"
                    @click="searchQuery = null"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>

        </b-row>
      </div>

      <!-- 列表 -->
      <b-table
        ref="refDataListTable"
        :items="getDevicePCListData"
        :fields="tableColumnsFilter"
        responsive
        striped
        hover
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        :stacked="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        <!-- 忙碌中 -->
        <template #table-busy>
          <b-skeleton-table
            :rows="5"
            :columns="tableColumnsFilter.length"
            :table-props="{ bordered: true, striped: true }"
          />
        </template>

        <!-- 查無資料 -->
        <template #empty>
          <div class="text-center my-5 animate__animated animate__fadeIn">
            <b-img
              :src="$store.state.app.notFoundImg"
              fluid
              width="480"
              alt="查無資料"
            />
          </div>
        </template>

        <!-- 欄位: 編號 -->
        <template #cell(id)="data">
          <div class="table-col">
            <span>{{ data.item.id }}</span>
          </div>
        </template>

        <!-- 欄位: IP位置 -->
        <template #cell(assigned_ip)="data">
          <!-- <b-link
            class="font-weight-bold d-block text-nowrap item-description"
            :to="{ name: 'admin-device-pc-view', params: { id: data.item.id } }"
          >
            {{ data.item.assigned_ip }}
          </b-link> -->

          <div class="table-col">
            <div class="d-block selection-group d-flex align-items-center">
              <div
                class="mr-25 font-weight-bold text-primary text-nowrap item-description"
                @click="onSubmitEdit(data.item)"
              >
                {{ data.item.assigned_ip }}
              </div>
              <span
                class="selection-btn-icon"
                @click="onSubmitView(data.item)"
              >
                <b-img
                  src="/admin/images/table/detail.svg"
                  height="14"
                  width="14"
                  class="cursor-pointer mb-25"
                />
              </span>
            </div>
          </div>
        </template>

        <!-- 欄位: 說明 -->
        <template #cell(description)="data">
          <div
            class="table-col item-description"
            @click="onSubmitShowDescription(data.item)"
          >
            <!-- v-b-tooltip.hover.focus.v-secondary
            :title="`${data.item.description ? data.item.description : ''}`" -->
            {{ data.item.description ? data.item.description : '---' }}
          </div>
        </template>

        <!-- 欄位: CPU型號 -->
        <template #cell(cpu_model)="data">
          <div
            v-if="data.item.cpu.length > 0"
            style="min-width: 85px;"
          >
            <div :id="`cpu_model-tooltip-${data.index}`">
              <div class="d-block text-nowrap item-description">
                {{ data.item.cpu[0].name }}
              </div>
              <small class="text-muted">
                <div class="item-description">
                  {{ data.item.cpu[0].serialNumber }}
                </div>
              </small>
            </div>

            <b-tooltip
              v-if="data.item.cpu.slice(1).length > 0"
              :target="`cpu_model-tooltip-${data.index}`"
              variant="secondary"
            >
              <div class="text-left">
                <div>其他CPU</div><br>
                <div
                  v-for="(item, index) in data.item.cpu.slice(1)"
                  :key="index"
                >
                  <div>{{ item.name }}</div>
                  <small>
                    <div class="item-description">
                      {{ data.item.cpu[0].serialNumber }}
                    </div>
                  </small>
                  <hr
                    v-if="index !== data.item.cpu.length - 2"
                    class="p-25"
                  >
                </div>
              </div>
            </b-tooltip>
          </div>

          <div v-else>
            ---
          </div>
        </template>

        <!-- 欄位: 記憶體大小 -->
        <template #cell(memory_size)="data">
          <div style="min-width: 85px;">
            <!-- {{ parseInt(data.item.memory_size, 10).toLocaleString() }} -->
            {{ data.item.memory_size ? `${parseInt(data.item.memory_size, 10).toLocaleString()} GB` : '---' }}
          </div>
        </template>

        <!-- 欄位: 硬碟大小 -->
        <template #cell(hard_disk_size)="data">
          <div style="min-width: 85px;">
            <!-- {{ parseInt(data.item.hard_disk_size, 10).toLocaleString() }} -->
            {{ data.item.hard_disk_size ? `${parseInt(data.item.hard_disk_size, 10).toLocaleString()} GB` : '---' }}
          </div>
        </template>

        <!-- 欄位: 使用者 -->
        <template #cell(user)="data">
          <div class="table-col">
            {{ data.item.user_info.name ? data.item.user_info.name : '---' }}
          </div>
        </template>

        <!-- 欄位: 上次更新 -->
        <template #cell(updated_at)="data">
          <div style="min-width: 85px;">
            <div v-if="data.item.updated_at">
              <span
                v-b-tooltip.hover.focus.v-secondary
                :title="`${moment(data.item.updated_at).format('HH:mm')}`"
              >
                {{ updateOnline(data.item.updated_at, refonlineTime) }}
              </span>
            </div>

            <div v-else>
              <span>---</span>
            </div>
          </div>
        </template>

        <!-- 欄位: 動作 -->
        <template #cell(actions)="data">
          <!-- ="data" -->
          <div class="d-flex mb-50">
            <div
              class="actions-link-btn"
              @click="onSubmitView(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="顯示詳情"
                src="/admin/images/table/detail.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>

            <div
              class="actions-link-btn"
              @click="onSubmitEdit(data.item)"
            >
              <b-img
                v-b-tooltip.hover.focus.v-secondary
                title="編輯"
                src="/admin/images/table/edit.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </div>
        </template>
      </b-table>

      <!-- 顯示頁數/分頁 -->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- 顯示頁數 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">第 {{ dataMeta.from }} - {{ dataMeta.to }} 筆，共 {{ dataMeta.of }} 筆</span>
          </b-col>

          <!-- 分頁 -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalNum"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>

    <!-- 編輯主機 -->
    <edit-modal
      v-if="selected"
      ref="editModal"
      :device-item="selected"
      @refetch-data="refetchTable"
    />

    <b-modal
      v-if="selected"
      id="description-modal"
      centered
      hide-footer
    >
      <template #modal-title>
        <h4 class="m-0">
          主機說明
        </h4>
      </template>

      <div>
        <!-- <div class="device-name text-primary">
          {{ selected.name }}
        </div> -->

        <div class="device-description">{{ selected.description }}
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
// API
import { onUnmounted } from '@vue/composition-api'
import moment from 'moment'

// UI
import {
  BCard, BRow, BTable, BCol, BPagination, VBTooltip, BDropdown, BImg, BTooltip, // BLink,
  BDropdownForm, BFormInput, BInputGroupPrepend, BInputGroup, BFormGroup, BFormCheckbox, BSkeletonTable,
  BInputGroupAppend, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import router from '@/router'
import store from '@/store'

// Component
import { useDevicePCList, useDeviceSetting } from '../useDevice'
import EditModal from './DeviceListEditModal.vue'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import useStoreModule from '../useStoreModule'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    // BLink,
    BTooltip,
    BFormInput,
    BTable,
    BModal,
    BPagination,
    BDropdown,
    // BDropdownItem,
    BDropdownForm,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BSkeletonTable,

    vSelect,
    EditModal,
    // AuthModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      selected: null,
      title: router.currentRoute.meta.pageTitle,
    }
  },
  computed: {
    tableColumnsFilter() {
      // if (this.authAbility.user !== 'write') {
      //   return this.tableColumns
      //     .filter(f => f.select && f.key !== 'actions')
      // }
      return this.tableColumns
        .filter(f => f.select)
    },
    tableColumnsSearchable() {
      // if (this.authAbility.user !== 'write') {
      //   return this.tableColumns
      //     .filter(f => f.searchable && f.key !== 'actions')
      // }
      return this.tableColumns
        .filter(f => f.searchable)
    },
    authAbility() {
      return this.$store.state.app.ability
    },
  },
  mounted() {
    this.timer = setInterval(this.onlineTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    moment,
    // (觸發)顯示主機說明
    onSubmitShowDescription(item) {
      if (!item.description) return
      this.selected = item
      // this.useSwalAlertInfomation(item.name, item.description)
      setTimeout(() => { this.$bvModal.show('description-modal') }, 200)
    },

    // (觸發)編輯主機
    onSubmitEdit(item) {
      this.selected = item
      setTimeout(() => { this.$refs.editModal.getData() }, 200)
      // this.$router.push({ name: 'admin-device-pc-view', params: { id: item.id } })
    },

    // (觸發)前往主機
    onSubmitView(item) {
      this.$router.push({ name: 'admin-device-pc-view', params: { id: item.id } })
    },
  },
  setup() {
    // 註冊模組
    const DEVICE_ADMIN_STORE_MODULE_NAME = 'admin-device'

    if (!store.hasModule(DEVICE_ADMIN_STORE_MODULE_NAME)) store.registerModule(DEVICE_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(DEVICE_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(DEVICE_ADMIN_STORE_MODULE_NAME)
    })

    const {
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      timeRange,

      setDevicePCDelete,
      getDevicePCListData,
    } = useDevicePCList()

    const {
      ui,
      statusOptions,
      refonlineTime,
      onlineTime,
      updateOnline,
    } = useDeviceSetting()

    const {
      useAlertToast,
    } = useAlert()

    return {
      ui,
      useAlertToast,
      statusOptions,
      refDataListTable,
      perPage,
      totalNum,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      tableColumns,
      dataMeta,
      refetchData,
      refetchTable,
      timeRange,

      setDevicePCDelete,
      getDevicePCListData,
      refonlineTime,
      onlineTime,
      updateOnline,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.item-description {
  // background-color: red;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  // font-size: 1.4rem;
}

.device-name {
  font-size: 18px;
  margin-bottom: 8px;
}

.device-description {
  white-space: pre-wrap;
}
</style>
